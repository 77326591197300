<template>
  <div class="box">
    <el-alert class="mb-2" type="info" :closable="false" show-icon>
      <div>
        按住
        <kbd>
          Windows:
          <b>Alt</b>
        </kbd>
        /
        <kbd>
          Mac:
          <b>Option</b>
        </kbd>
        键可以进行缩放
      </div>
    </el-alert>
    <div class="table-box-cover-border">
      <div class="move-line"></div>
      <div class="move-line-l"></div>
      <div class="table-box-cover-box">
        <div class="table-box-cover">
          <div
            v-if="questions"
            class="table-box"
            :class="{ 'height-table': questions.length > 30 }"
          >
            <div class="order-box mr-1">
              <div
                :class="{
                  'order-item': true,
                  'is-correct':
                    question.userAnswer && question.userAnswer.is_correct === 1,
                  'is-error':
                    question.userAnswer && question.userAnswer.is_correct !== 1
                }"
                v-for="(question, index) in questions"
                :key="question.id"
              >
                <span v-show="questions.length < 31">
                  {{ index + 1 }}
                </span>
              </div>
            </div>
            <div class="time-box">
              <div class="time-line-top">
                <div
                  class="time-line-second"
                  v-for="second in Math.ceil(this.sectionTime / 60)"
                  :key="second"
                >
                  <div class="time-second-box">
                    <span class="time-box-start" v-show="second === 1">
                      {{ instant.formatSecondToMMSS((second - 1) * 60) }}
                    </span>
                    <span
                      class="time-box-end 1-M"
                      :class="{
                        '5-M': second % 5 === 0,
                        '2-M': second % 2 === 0
                      }"
                    >
                      {{ instant.formatSecondToMMSS(second * 60) }}
                    </span>
                    &nbsp;
                    <!-- <div v-for="i in 60" :key="i" class="1-s">
                      1
                    </div> -->
                  </div>
                </div>
              </div>
              <div
                class="time-children"
                v-for="(question, index) in questions"
                :key="question.id"
                :style="getQuestionWidth(question.userAnswer)"
                v-show="question.userAnswer"
              >
                <div
                  class="time-line"
                  :style="
                    `top: ${index * (questions.length > 30 ? 0.5 : 1.5)}rem;`
                  "
                  :class="{
                    'is-correct':
                      question.userAnswer &&
                      question.userAnswer.is_correct === 1,
                    'is-error':
                      question.userAnswer &&
                      question.userAnswer.is_correct !== 1
                  }"
                >
                  <template v-if="question.userAnswer">
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :visible-arrow="false"
                      placement="top"
                    >
                      <div slot="content">
                        <b>#{{ index + 1 }}</b>
                        <div>
                          {{
                            question.userAnswer.metadata
                              ? ` ` +
                                showTotalTime(
                                  question.userAnswer.metadata.activities
                                )
                              : ""
                          }}
                        </div>
                      </div>
                      <span
                        style="display:block"
                        v-show="questions.length < 31"
                      >
                        {{
                          question.userAnswer.metadata
                            ? showTotalTime(
                                question.userAnswer.metadata.activities
                              )
                            : "&nbsp;"
                        }}
                      </span>
                      <span
                        style="display:block"
                        v-show="questions.length > 30"
                      >
                        &nbsp;
                      </span>
                    </el-tooltip>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :visible-arrow="false"
                      placement="top"
                    >
                      <div slot="content">
                        You left the screen for 10 seconds
                      </div>
                      <span
                        class="text-warning leave-Icon"
                        v-if="getLeaveTime(question.userAnswer)"
                      >
                        <i class="fas fa-exclamation-triangle"></i>
                      </span>
                    </el-tooltip>
                  </template>
                  <template v-else>
                    &nbsp;
                  </template>
                </div>
              </div>
            </div>
            <div class="order-box ml-1">
              <div
                :class="{
                  'order-item': true,
                  'is-correct':
                    question.userAnswer && question.userAnswer.is_correct === 1,
                  'is-error':
                    question.userAnswer && question.userAnswer.is_correct !== 1
                }"
                v-for="(question, index) in questions"
                :key="question.id"
              >
                <span v-show="questions.length < 31">
                  {{ index + 1 }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="resize-button">
      <el-tooltip
        class="item"
        effect="dark"
        :visible-arrow="false"
        placement="top"
        content="Zoom Out"
      >
        <el-button size="mini" type="primary" class="zoom-out" plain>
          <i class="fas fa-minus"></i>
        </el-button>
      </el-tooltip>
      <el-tooltip
        class="item"
        effect="dark"
        :visible-arrow="false"
        placement="top"
        content="Reset"
      >
        <el-button size="mini" type="primary" class="reset" plain>
          <i class="fas fa-redo"></i>
        </el-button>
      </el-tooltip>
      <el-tooltip
        class="item"
        effect="dark"
        :visible-arrow="false"
        placement="top"
        content="Zoom In"
      >
        <el-button size="mini" type="primary" class="zoom-in" plain>
          <i class="fas fa-plus"></i>
        </el-button>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user, instant } from "@ivy-way/material";
import $ from "jquery";

export default {
  metaInfo() {},

  components: {},

  mixins: [],

  props: ["questions", "sectionTime"],
  data() {
    return {
      totalTime: 0
    };
  },
  computed: {
    user() {
      return user;
    },
    instant() {
      return instant;
    }
  },
  watch: {
    questions() {
      if (this.questions) {
        this.getTotalTime();
      }
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.moveLine();
      this.resize();
    });
    if (this.questions) {
      this.getTotalTime();
    }
  },

  methods: {
    resize() {
      let vm = this;
      $(".table-box-cover-border").on("mousewheel", function(event) {
        if (event.altKey) {
          if (event.preventDefault) {
            event.preventDefault();
          } else {
            event.returnValue = false;
          }
          let e = $(this).find(".table-box-cover");
          if (event.originalEvent.wheelDelta < 0) {
            // 向下滚动
            let width = e.width() - 100 < 375 ? 375 : e.width() - 100;
            e.width(width);
            vm.showTimeText(e[0]);
          } else {
            // 向上滚动
            e.width(e.width() + 100);
            vm.showTimeText(e[0]);
          }
        }
      });
      $(".box").each(function(index, e) {
        let element = $(e).find(".table-box-cover")[0];
        $(e)
          .find(".zoom-in")
          .click(() => {
            $(element).width($(element).width() + 100);
            vm.showTimeText(element);
          });
        $(e)
          .find(".zoom-out")
          .click(() => {
            let width =
              $(element).width() - 100 < 375 ? 375 : $(element).width() - 100;
            $(element).width(width);
            vm.showTimeText(element);
          });

        $(e)
          .find(".reset")
          .click(() => {
            $(element).css("width", "100%");
            vm.showTimeText(element);
          });
      });

      $(".table-box-cover").each(function(index, e) {
        vm.showTimeText(e);
      });
    },
    showTimeText(e) {
      // if ($(e).width() > 4500) {
      //   $(".leave-Icon").show();
      // } else {
      //   $(".leave-Icon").hide();
      // }
      if ($(e).width() > 1500) {
        $(e)
          .find(".2-M")
          .hide();
        $(e)
          .find(".5-M")
          .hide();
        $(e)
          .find(".1-M")
          .show();
      } else if ($(e).width() > 1000) {
        $(e)
          .find(".5-M")
          .hide();
        $(e)
          .find(".1-M")
          .hide();
        $(e)
          .find(".2-M")
          .show();
      } else {
        $(e)
          .find(".1-M")
          .hide();
        $(e)
          .find(".2-M")
          .hide();
        $(e)
          .find(".5-M")
          .show();
      }
    },
    getQuestionWidth(answer) {
      let style = "";
      if (answer && answer.metadata) {
        let time = this.getQuestionTotalTime(answer.metadata.activities);
        if (this.totalTime > 0 && this.sectionTime > time) {
          style = `width:${(time / this.sectionTime) * 100}%`;
        }
      }
      return style;
    },
    moveLine() {
      $(document).ready(function() {
        $(".table-box-cover-border").mousemove(function(event) {
          // console.log($(this).width());
          // console.log("event.pageX: " + event.pageX);
          // console.log("this.offsetLeft: " + this.offsetLeft);
          var mouseX = event.pageX - this.offsetLeft;
          var mouseY = event.pageY - this.offsetTop;
          $(this)
            .find(".move-line")
            .css({
              left: mouseX + "px"
            });
          $(this)
            .find(".move-line-l")
            .css({
              top: mouseY + "px"
            });
        });
      });
    },
    getLeaveTime(answer) {
      let leave = null;
      if (
        answer &&
        answer.metadata &&
        answer.metadata.leaveActivities &&
        answer.metadata.leaveActivities.length > 0
      ) {
        answer.metadata.leaveActivities.forEach(function(ele, index) {
          if (ele.type === "Leave") {
            leave = ele;
          }
        });
      }
      return leave;
    },
    getTotalTime() {
      let totalTime = 0;
      this.questions.forEach(question => {
        if (question.userAnswer && question.userAnswer.metadata) {
          let time = this.getQuestionTotalTime(
            question.userAnswer.metadata.activities
          );
          totalTime += time;
        }
      });
      this.totalTime = totalTime;
    },
    showTotalTime(activities) {
      let totalTime = 0;
      activities.forEach(active => {
        if (
          active.length > 1 &&
          active[0]["testTime"] &&
          active[active.length - 1]["testTime"]
        ) {
          totalTime +=
            active[0]["testTime"] - active[active.length - 1]["testTime"];
        }
      });
      if (totalTime < 0) totalTime = 0;
      if (totalTime < 0) totalTime = 0;
      return this.instant.formatSecondToMMSS(totalTime);
    },
    getQuestionTotalTime(activities) {
      let totalTime = 0;
      activities.forEach(active => {
        if (
          active.length > 0 &&
          active[0]["testTime"] &&
          active[active.length - 1]["testTime"]
        ) {
          totalTime +=
            active[0]["testTime"] - active[active.length - 1]["testTime"];
        }
      });
      if (totalTime < 0) totalTime = 0;
      if (totalTime < 0) totalTime = 0;
      return totalTime;
    },
    setWidth(activities) {
      let totalTime = 0;
      activities.forEach(active => {
        if (
          active.length > 0 &&
          active[0]["testTime"] &&
          active[active.length - 1]["testTime"]
        ) {
          totalTime +=
            active[0]["testTime"] - active[active.length - 1]["testTime"];
        }
      });
      return totalTime;
    }
  }
};
</script>

<style scoped>
.table-box-cover-border {
  position: relative;
  border: 1px solid #dee2e6;
}
.table-box-cover-box {
  position: relative;
  overflow: scroll;
}
.table-box-cover {
  position: relative;
  width: 100%;
  overflow: auto;
}

.time-line-top {
  position: absolute;
  top: -1.5rem;
  left: 0;
  right: 0;
  display: flex;
}
.time-line-second {
  flex: 1;
  text-align: center;
  background-color: #dee2e6;
  color: #ccc;
  cursor: pointer;
}
.time-line-second:nth-child(odd) {
  background-color: #f2f2f2;
}
.table-box {
  display: flex;
  width: 100%;

  padding: 1rem;
  padding-top: 3rem;

  user-select: none;
}
.move-line {
  position: absolute;
  top: -0;
  bottom: -0;
  left: 1px;
  width: 1px;
  z-index: 0;
  background-color: orange;
}
.move-line-l {
  position: absolute;
  left: -0;
  right: -0;
  top: 1px;
  height: 1px;
  z-index: 0;
  background-color: orange;
}
.time-box {
  display: flex;
  flex: 1;
  position: relative;
}
.time-children {
  /* flex: 1; */
  position: relative;
}

.time-line {
  font-size: 1rem;
  height: 1.5rem;
  line-height: 1.5rem;
  position: absolute;
  top: 0;
  background-color: var(--themeColor);
  color: white;
  width: 100%;
  text-align: center;
  position: relative;
}
.time-line .leave-Icon {
  position: absolute;
  left: 0;
  top: -0.25rem;
  font-size: 1rem;
}

.time-line:hover {
  opacity: 0.8;
  cursor: pointer;
}
.order-item {
  font-size: 1rem;
  height: 1.5rem;
  line-height: 1.5rem;
  width: 1.5rem;
  /* border: 1px solid #dee2e6; */
  background-color: #dee2e6;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}
.order-item:nth-child(odd) {
  opacity: 0.5;
}
.height-table .time-line,
.height-table .order-item {
  height: 0.5rem;
  line-height: 0.5rem;
}
.order-item.is-correct,
.is-correct {
  background-color: #67c23a;
  color: white;
}
.order-item.is-error,
.is-error {
  background-color: #f56c6c;
  color: white;
}
.time-second-box {
  position: relative;
}
.time-box-start {
  font-size: 0.6rem;
  position: absolute;
  display: block;
  top: -1rem;
  left: -0.8rem;
  font-weight: 500;
}
.time-box-end {
  font-size: 0.6rem;
  position: absolute;
  display: block;
  top: -1rem;
  right: -0.8rem;
  font-weight: 500;
}
.resize-button {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>
